import type { MessageType } from '@marty-js/design/src/utils/translation';

export const messageFr: MessageType = {
  sdk: {
    to_article_html: {
      rating: 'Note',
      toProduct: 'Voir le produit',
      toSheet: 'Voir la fiche',
      readReview: 'Lire le test',
      readServiceReview: "Lire l'avis",
      dataSheet: 'Fiche technique',
      plan: 'Forfait',
      price: {
        price: 'Prix',
        at: 'Chez',
        for: 'à',
        toOffer: "Voir l'offre",
      },
    },
    cta: {
      download: 'Télécharger',
      'online-service': "Voir l'offre",
      plan: "Voir l'offre",
    },
    template: {
      download_listing: {
        metaTitle: 'Téléchargements {{title}}: {{count}} logiciels (gratuit) - Clubic',
      },
      comparative_plan: {
        title_compare: 'Comparer tous les ',
      },
      product_listing: {
        title_compare: 'Comparer tous les ',
      },
      long_read_listing: {
        metaDescription:
          'Retrouvez tous les {{title}} sur Clubic pour faire le meilleur choix (prix, caractéristiques, bons plans).',
      },
      best_pick_listing: {
        metaDescription:
          'Retrouvez tous les {{title}} sur Clubic pour faire le meilleur choix (prix, caractéristiques, bons plans).',
      },
      review_listing: {
        metaDescription:
          'Retrouvez tous les {{title}} sur Clubic pour faire le meilleur choix (prix, caractéristiques, bons plans).',
      },
      tutorial_listing: {
        metaDescription:
          'Retrouvez tous les {{title}} sur Clubic pour tirer le meilleur parti des nouvelles technologies.',
      },
      simple_listing: {
        metaTitle: '{{title}} - Clubic',
        metaDescription:
          'Retrouvez toutes les actualités {{title}} sur Clubic pour ne rien manquer des dernières sorties, infos, nouveautés tech.',
      },
      homepage: {
        featured: 'À la une',
      },
      main_listing: {
        all_news: "Toute l'actualité",
        headline: 'Actualités',
        readMore: 'Lire la suite',
        readLess: 'Replier la suite',
        dominant_layout: {
          'online-service': 'Les meilleurs services',
          download: 'les meilleurs logiciels',
          product: 'produits populaires',
        },
        insert: {
          lab: 'Les derniers tests du labo',
          bestPick: "Guide d'achat {{title}}",
        },
        best_product: {
          automatic_main_title: {
            product: '{{section}} : Les meilleurs produits',
            download: '{{section}} : Les meilleurs logiciels',
            'online-service': '{{section}} : Les meilleurs services',
            plan: '{{section}} : Les meillers forfaits',
            default: '{{section}} : Les meilleurs produits',
          },
          ctaLabel: {
            product: 'Voir le produit',
            download: 'Télécharger',
            'online-service': 'Télécharger',
            default: "Voir l'offre",
            plan: 'Voir les offres',
          },
          reviewLabel: {
            'service-review': "Lire l'avis",
            'product-review': 'Lire le test',
            download: "Lire l'avis",
            plan: "Lire l'avis",
            default: "Lire l'article",
          },
          itemLinkLabel: {
            product: 'Voir la fiche',
            download: 'Télécharger',
            'online-service': 'Télécharger',
            default: 'Voir la fiche',
            plan: 'Voir les offres',
          },
        },
        brand_selection: 'Top marques',
        metaDescription:
          'Retrouvez toutes les actualités {{title}} sur Clubic pour ne rien manquer des dernières sorties, infos, nouveautés tech.',
      },
      download: {
        rates: 'notes',
        days: 'jours',
        startDownloadTitle: 'Téléchargement de {{title}}',
        recentNews: 'Articles récents',
        thirtyDays: '30 jours',
        marks: 'notes',
        download: 'Télécharger',
        openWeb: 'Télécharger (Web)',
        for: 'pour',
        update: 'Mise à jour',
        metaTitle: 'Télécharger {{title}} (gratuit) {{os}} - Clubic',
        alsoAvailable: '{{title}} : également disponible sur...',
        linked_categories: 'Les rubriques liées à {{title}}',
        commend_download: 'Recommandez-le',
        validate_rate: 'Je valide mon vote',
        voting_thanks: "Merci d'avoir voté",
        discovery: 'Découvrir',
        similar_downloads: 'Logiciels similaires',
      },
      listing_download: {
        discovery_moment: 'Découverte du moment',
        filter_by_os: 'Filtre par OS',
        filter_all_os: 'Filtre : tous les OS',
        categories: 'Catégories',
        title_index: 'Téléchargement de logiciels, applications et jeux gratuits',
        title: '{{title}} : {{total}} logiciels à télécharger pour Windows, Mac, iOS, Android et Linux',
        introduction:
          'Bienvenue sur la logithèque de Clubic, la plateforme de référence pour découvrir, télécharger et installer en toute sécurité des logiciels incontournables sur Windows, macOS et Linux mais également les applications mobiles phares pour Android et iOS. Nos fiches de présentation vous proposent une prise en main facile et rapide suite à votre téléchargement. Partagez vos impressions et découvrez les astuces de la communauté en commentaires.',
        subtitle: 'Les {{total}} meilleurs logiciels',
        all_softwares: 'Tous les logiciels',
        result: '{{result}} logiciels',
      },
    },
    filter: {
      price: 'Prix',
      brand: 'Marque',
      refine_my_search: 'Affiner ma recherche',
    },
    button: { read_more_label: 'Voir Plus', summary: 'Sommaire' },
    read_more: 'Lire la suite',
    read_less: 'Replier la suite',
    product: {
      metaTitle: '{{title}} : Meilleur Prix, Avis et Actualités - Clubic',
      ogMetaTitle: '{{title}} : Meilleur Prix, Avis et Actualités',
      twitterMetaTitle: '{{title}} : Meilleur Prix, Avis et Actualités',
      summary: 'Résumé',
      alternatives: 'Alternatives',
      last_products: 'Derniers produits',
      review: 'Test',
      comparative: 'Comparatif',
      datasheet: 'Fiche technique',
      news: 'Actualités',
      shopPrice: '{{price}} chez {{shop}}',
      noPrice: "Voir l'offre",
      releaseDate: 'Date de sortie : {{date}}',
    },
    comments: {
      disclaimer:
        "Restez toujours objectif, pertinent et juste dans vos commentaires. Il est strictement interdit d'intégrer des commentaires discriminants, injurieux, racistes, sexistes, etc.",
      anonymous_disclaimer:
        "Restez toujours objectif, pertinent et juste dans vos commentaires. Il est strictement interdit d'intégrer des commentaires discriminants, injurieux, racistes, sexistes, etc.\nMerci de vous connecter à votre compte clubic pour laisser un commentaire.",
      seeMore: 'Charger plus de commentaires',
      goToForum: 'Allez sur le forum pour voir plus de commentaires et répondre',
      login: 'Vous devez être connecté pour commenter',
      reply_to_needs_login: 'Vous devez être connecté pour laisser un commentaire.',
      like_needs_login: 'Vous devez être connecté pour laisser un commentaire.',
      post: 'Poster mon commentaire',
      replyTo: 'En réponse à {{username}}',
      responseTo: 'En réponse à {{username}}',
      seeAllCommentsAndAnswer: 'Voir tous les commentaires et répondre',
      seeForum: 'Ouvrir le forum',
      title: 'Commentaires {{count}}',
      bestComments: 'Les meilleurs commentaires',
      labels: {
        like: "J'aime",
        reply: 'Répondre',
        delete: 'Supprimer',
      },
    },
    item: {
      sponsor_by: 'Sponsorisé par',
      read_more: 'Sur le même sujet',
      last_protocol_test: 'Les derniers protocoles de test',
      last_deals: 'Les derniers bons plans',
      last_review: 'Les derniers tests',
      last_service_review: 'Les derniers avis',
      last_news: 'Dernières actualités',
      trends: 'Les tendances',
      last_best_pick: "Les derniers guides d'achat",
      last_tutorials: 'Les derniers tutoriels',
      last_guides: 'Les derniers guides',
      all_best_pick: 'Voir tous nos guides {{sectionTitle}}',
      all_tutorials: 'Voir tous nos tutoriels {{sectionTitle}}',
      all_reviews: 'Voir tous nos tests {{sectionTitle}}',
      all_service_reviews: 'Voir tous nos avis {{sectionTitle}}',
      all_guides: 'Voir tous nos guides {{sectionTitle}}',
    },
    author: {
      access: 'Articles de {{name}}',
      access_vowel: "Articles d'{{name}}",
      by: 'Par',
      missing_summary: 'Aucun résumé disponible',
    },
    authorLine: {
      published: 'Publié le ',
      sponsoredBy: 'sponsorisé par ',
      sponsoredNoBy: 'sponsorisé',
      comments: 'commentaires',
    },
  },
};
